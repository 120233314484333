import React from "react";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import TargetFullBanner from "../components/TargetFullBanner";
import DipendentePensionatoPrivato from "../sections/DipendentePensionatoPrivato";
import SliderOne from "../sections/SliderOne";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import CessioneFinanziamenti from "../sections/CessioneFinanziamenti";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import DefaultButton from "../components/buttons/DefaultButton";

const StyledWpPage = styled.div`
  .wp-page {
    &__title {
      text-align: center;
    }

    &__vision {
      color: #527191;
      text-align: center;
    }

    &__modello {
      h2 {
        color: #527191;
      }
    }

    &__ul li::marker {
      color: #e7c700;
    }

    &__pilastri {
      &__item {
        background-color: #e7c700;
        padding: 2em 0;
        border-radius: 12px;
        color: #527191;
        width: 325px;
      }
    }

    &__blue-banner {
      background-color: #e8f0f6;
    }

    &__slug a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }

    &__header {
      background: linear-gradient(
        180deg,
        rgba(246, 234, 199, 0.63) 0%,
        rgba(255, 255, 255, 0) 60%
      );
      mix-blend-mode: darken;

      .intro {
        color: #527191;
      }

      .color-gray {
        color: #6d767b;
      }

      .color-yellow {
        color: #e7c700;
      }

      .content {
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
    padding-bottom: 3em;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
    }
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: auto;
`;

export default function Manifesto(props) {
  const {
    visionTopRightImage,
    visionBottomCenterImage,
    visionTopCenterImage,
    visionBottomRightImage,
    visionTopCenterBlueImage,
  } = useStaticQuery(
    graphql`
      query {
        visionTopRightImage: file(
          relativePath: { eq: "vision-top-right.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }

        visionBottomCenterImage: file(
          relativePath: { eq: "vision-bottom-center.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }

        visionTopCenterImage: file(
          relativePath: { eq: "vision-top-center.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }

        visionBottomRightImage: file(
          relativePath: { eq: "vision-bottom-right.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }

        visionTopCenterBlueImage: file(
          relativePath: { eq: "vision-top-center-blue.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  );

  const topRightImage = getImage(visionTopRightImage);
  const bottomCenterImage = getImage(visionBottomCenterImage);
  const topCenterImage = getImage(visionTopCenterImage);
  const bottomRightImage = getImage(visionBottomRightImage);
  const topCenterBlueImage = getImage(visionTopCenterBlueImage);

  const bgTopRightImage = convertToBgImage(topRightImage);
  const bgBottomCenterImage = convertToBgImage(bottomCenterImage);
  const bgTopCenterImage = convertToBgImage(topCenterImage);
  const bgBottomRightImage = convertToBgImage(bottomRightImage);
  const bgTopCenterBlueImage = convertToBgImage(topCenterBlueImage);

  return (
    <Layout 
      helmetTitle="Manifesto per la sostenibilità sociale - Prestitifaidate"
      helmetDescription="Per noi, la sostenibilità sociale rappresenta uno strumento di crescita personale e collettiva. Scopri il Manifesto per la sostenibilità sociale di PrestitiFaidaTe."
    >
      <StyledWpPage id="page-content" color="transparent">
        <div className="wp-page__header mb-5">
          <div className="wrapper container d-flex align-items-center position-relative justify-content-between">
            <div className="d-flex align-items-center w-100 justify-content-between content pt-5 pt-md-0">
              <div className="d-flex flex-column">
                <span className="color-yellow fw-bold fs-3">Sosteniamo</span>
                <span className="color-gray fs-4">la collettività!</span>
              </div>
              <StaticImage
                src="../assets/images/testata-manifesto-persone.png"
                className="img-fluid my-5"
                alt="Persone"
                placeholder="blurred"
                layout="constrained"
                width={879}
                height={319}
              />
            </div>
          </div>
        </div>
        <div id="page-content" className="container mx-auto">
          <div className="wp-page__slug mb-5 mt-4 fs-5">
            <Link to="/" className="fw-bold">
              HOME
            </Link>{" "}
            &gt; <Link to="/chi-siamo">Chi siamo</Link> &gt;{" "}
            <Link to="/manifesto/">Manifesto per la sostenibilità sociale</Link>
          </div>
          <h1 className="wp-page__title mb-5 display-6 fw-bold">
            Manifesto per la sostenibilità sociale
          </h1>
          <h2 className="wp-page__title mb-4 fw-bold">Un modello etico</h2>
          <div className="wp-page__content">
            <p>
              Per noi, la sostenibilità sociale rappresenta uno strumento di
              crescita personale e collettiva. A questa logica, rispondono anche
              le attività economiche e finanziarie.
            </p>
            <div className="wp-page__modello">
              <h2 className="mt-3 text-center">
                L’obiettivo è un modello di sostenibilità intrinsecamente
                connesso:
              </h2>
              <div className="wp-page__vision my-5">
                <div className="row justify-content-center">
                  <StyledBackgroundImage
                    Tag="div"
                    {...bgTopRightImage}
                    preserveStackingContext
                    className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--top-right p-4"
                    style={{ backgroundPosition: "top right" }}
                  >
                    alla qualità della vita della persona e della comunità
                  </StyledBackgroundImage>
                  <StyledBackgroundImage
                    Tag="div"
                    {...bgBottomCenterImage}
                    preserveStackingContext
                    className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--bottom-center p-5"
                    style={{ backgroundPosition: "bottom center" }}
                  >
                    all’impegno per la trasparenza del credito
                  </StyledBackgroundImage>
                  <StyledBackgroundImage
                    Tag="div"
                    {...bgTopCenterImage}
                    preserveStackingContext
                    className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--top-center p-5"
                    style={{ backgroundPosition: "top center" }}
                  >
                    alla garanzia del potere d’acquisto per tutti
                  </StyledBackgroundImage>
                </div>
                <div className="row justify-content-center">
                  <StyledBackgroundImage
                    Tag="div"
                    {...bgBottomRightImage}
                    preserveStackingContext
                    className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--bottom-right p-4"
                    style={{ backgroundPosition: "bottom right" }}
                  >
                    all’ascolto delle istanze individuali e collettive
                  </StyledBackgroundImage>
                  <StyledBackgroundImage
                    Tag="div"
                    {...bgTopCenterBlueImage}
                    preserveStackingContext
                    className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--top-center-blue p-5"
                    style={{ backgroundPosition: "top center" }}
                  >
                    alla tutela del futuro delle generazioni che verranno.
                  </StyledBackgroundImage>
                </div>
              </div>
            </div>
            <p>
              La comunità, ossia lo sfondo e il contesto in cui individui e
              organizzazioni coesistono, acquisiscono competenze e si
              sviluppano, racchiude e integra sia la sfera privata che quella
              collettiva. Da qui, nasce una fondamentale simbiosi tra economia e
              solidarietà sociale che favorisce la sostenibilità di un sistema
              in equilibrio. Un sistema che garantisce l’integrità del capitale
              umano e finanziario.
            </p>
            <p>
              Questo modello concorre a superare la dicotomia tra interessi
              individuali e interessi diffusi, senza intaccare la capacità di
              ciascuno di crescere prosperare.
            </p>
            <StaticImage
              src="../assets/images/maialino.png"
              className="img-fluid mx-auto my-5"
              alt="Chi siamo"
              placeholder="blurred"
              layout="fixed"
              width={402}
              height={196}
            />

            <h2 className="wp-page__title mb-5 display-6 fw-bold">
              Le Possibili Azioni per Migliorare l’Attuale Sistema Finanziario
            </h2>
            <p>
              Tutte le realtà di credito hanno l’obiettivo di far circolare la
              ricchezza economica e metterla a servizio di chi ne ha bisogno.
              Negli ultimi anni, il mercato finanziario ha trascurato il proprio
              scopo originario: essere uno strumento a sostegno della società.
            </p>
            <p>
              Per questo, affermiamo che sia possibile e necessario indirizzare
              tutte le realtà di credito a perseguire il benessere collettivo,
              ponendo l’economia a servizio della comunità e non viceversa. In
              questo contesto, supportiamo tutte quelle istanze culturali,
              politiche e di mobilitazione sociale, che offrono soluzioni mirate
              per una vera ed effettiva parità delle opportunità di credito.
            </p>
            <p>
              A tal fine, proponiamo un indice di obiettivi aperto e non
              esaustivo da condividere con tutti gli attori che compongono il
              panorama finanziario:
            </p>
            <ul className="wp-page__ul">
              <li>promuovere la sicurezza di accesso al credito online</li>
              <li>
                superare la separazione tra interessi individuali e interessi
                diffusi
              </li>
              <li>
                concepire il diritto al credito come diritto che la persona
                esercita per costruire e realizzare il proprio progetto di vita.
              </li>
            </ul>
            <p>
              Da questi obiettivi scaturisce la nostra azione per orientare il
              mercato finanziario e metterlo a servizio dell’economia reale.
            </p>
            <StaticImage
              src="../assets/images/possibili-azioni.png"
              className="img-fluid mx-auto my-3"
              alt="Possibili azioni"
              placeholder="blurred"
              layout="fixed"
              width={312}
              height={246}
            />

            <section className="wp-page__pilastri mb-5">
              <h2 className="wp-page__title mb-5 display-6 fw-bold">
                I cinque pilastri della Nuova Economia
              </h2>
              <p>
                Combattiamo l’esclusione sociale e le diseguaglianze economiche
                attraverso specifiche offerte di credito personalizzate.
                <br />
                Il pensiero di economia che propugniamo, “la nuova economia”, si
                fonda su cinque pilastri essenziali:
              </p>
              <div className="row justify-content-center">
                <div className="wp-page__pilastri__item text-center col-12 col-lg-3 m-5">
                  <h3 className="fw-bold fs-6">DEMOCRAZIA ECONOMICA</h3>
                </div>
                <div className="wp-page__pilastri__item text-center col-12 col-lg-3 m-5">
                  <h3 className="fw-bold fs-6">FIDUCIA</h3>
                </div>
                <div className="wp-page__pilastri__item text-center col-12 col-lg-3 m-5">
                  <h3 className="fw-bold fs-6">RESPONSABILITÀ</h3>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="wp-page__pilastri__item text-center col-12 col-lg-3 m-5">
                  <h3 className="fw-bold fs-6">RECIPROCITÀ</h3>
                </div>
                <div className="wp-page__pilastri__item text-center col-12 col-lg-3 m-5">
                  <h3 className="fw-bold fs-6">CORRETTEZZA</h3>
                </div>
              </div>
              <p>
                Attraverso il nostro contributo, il ruolo dei cittadini si è
                potenziato ed è diventato sempre più consapevole rispetto
                all’importanza dell’accesso sicuro al credito online.
              </p>
            </section>
            <DefaultButton
              title="scopri chi siamo"
              className="btn-outline-dark mx-auto d-block mb-5"
              url={"/chi-siamo/"}
            />
          </div>
        </div>
      </StyledWpPage>
      <TargetFullBanner
        title="Dipendente pubblico, privato o pensionato?"
        description="Richiedere un prestito non è mai stato così facile, veloce e conveniente!"
        className="mt-2"
      />
      <DipendentePensionatoPrivato />
      <SliderOne />
      <MetodoPiuVeloce className="mt-2" />
      <CessioneFinanziamenti className="mb-5" />
    </Layout>
  );
}
